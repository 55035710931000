
import { Box } from "@mui/material"

import AdminSidebar from "../components/adminSideBar"
import Header from "../components/header"
// import CommisionHistoryComponent from "../components/commisionHistoryComponent"
import MessageBar from "./messageBar"
import WithdrawHistory from "../components/withdrawHistory"
import Header1 from "../components/header1"
// import OtpVerification from "../components/otpVerification"


const WithdrawView=()=>{

    return (<>
        <Box sx={{ bgcolor: { sm: "grey.100", xs: "#fff" }, minHeight: "calc(100vh - 120px)" }}>
            <MessageBar/>
            <Header1 />
            <Box sx={{ display: "flex", marginTop: "120px" }}>
                <AdminSidebar />
                {/* <CommisionHistoryComponent /> */}
                <WithdrawHistory/>
            </Box>
        </Box>
    </>)


}
export default WithdrawView